@import "src/styles/2-design-tokens/media-queries";

.tradition-section {
  .grid {
    align-items: center;
  }

  .team_text,
  .team_image {
    @include desktop {
      grid-column: auto / span 6;
    }
  }

  .team_image {
    @include desktop {
      width: 50vw;
      position: relative;
      left: 100%;
      transform: translate(-50vw, 0);
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .team_text p {
    margin-bottom: var(--spacing-small);
  }
}