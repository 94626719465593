form {
  width: 100%;
}

label {
  display: block;

  > span {
    font-size: var(--font-size_large);
    font-weight: bold;
  }

  > textarea,
  > input {
    margin-top: var(--spacing_12px);
  }

  & + label {
    margin-top: var(--spacing_36px);
  }
}

input[type="text"],
input[type="email"],
textarea,
select {
  display: block;
  width: 100%;
  background-color: var(--color_background);
  border: none;
  font: var(--font-family_root);
  font-size: var(--font-size_body);
  font-weight: normal;
  padding: 30px 36px;
  margin-left: 0;
  margin-right: 0;

  &:active {
    outline: 1px var(--color_primary)
  }
}

input[type="checkbox"] {
  width: 24px;
  height: 24px;
  border: 3px solid var(--color_text);
  background: transparent;
  margin: 0;
}

textarea {
  resize: none;
}

.radio {
  display: flex;
  align-items: center;

  input {
    margin-top: 0;
    margin-right: var(--spacing_12px);
  }
}