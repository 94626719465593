blockquote {
  margin: 0;
  font-size: var(--font-size_large);

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }
}

cite {
  display: block;
  color: var(--color_weak-text);
  font-style: normal;
  margin-top: var(--spacing_36px);

  span {
    color: var(--color_text);

    &:after {
      content: " - ";
    }
  }
}