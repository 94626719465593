$breakpoint_mobile: 768px;
$breakpoint_tablet: 1280px;

@mixin mobile {
  @media screen and (max-width: $breakpoint_mobile) {
    @content
  }
}

@mixin tablet {
  @media screen and (min-width: #{$breakpoint_mobile + 1}) and (max-width: $breakpoint_tablet) {
    @content
  }
}

@mixin desktop {
  @media screen and (min-width: #{$breakpoint_tablet + 1}) {
    @content
  }
}

@mixin tabletAndDesktop {
  @media screen and (min-width: #{$breakpoint_mobile + 1}) {
    @content
  }
}