@import "src/styles/2-design-tokens/media-queries";
@import "src/styles/6-layout/content-layout.scss";

section {
  @include contentLayout;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 auto;

  & + section {
    margin-top: var(--spacing_sections);
  }
}