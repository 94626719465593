@import "src/styles/2-design-tokens/media-queries";

.grid .service-list {
  margin-top: var(--spacing_image-translation);

  &_content,
  &_images {
    grid-row: 1;
  }

  &_content {
    grid-column: 1 / span 3;
    position: relative;
    z-index: 1;
    padding: var(--padding_cards--large);
    background-color: var(--color_background);

    h2 {
      text-align: left;
    }

    @include mobile {
      margin-left: calc(-1 * var(--padding_content));
    }

    @include tablet {
      grid-column-end: span 5;
    }

    @include desktop {
      grid-column-end: span 6;
    }
  }

  &_images {
    grid-column: 2 / span 2;
    display: grid;
    grid-template-areas: "image1 image1"
                         "image2 image3";
    grid-gap: var(--gap);
    margin-top: calc(-1 * var(--spacing_image-translation));
    margin-bottom: var(--spacing_image-translation);
    max-height: 640px;
    height: 100%;

    > *:first-child {
      height: 100%;
      width: 100%;
    }

    @for $i from 1 to 3 {
      > *:nth-child(#{$i}) {
        grid-area: image#{$i};
      }
    }

    @include mobile {
      width: calc(100% + var(--padding_content));
    }

    @include tablet {
      grid-column: 4 / span 5;
    }

    @include desktop {
      grid-column: 6 / span 7;
    }
  }

  p {
    font-size: var(--font-size_large);
  }
}


