.service-preview {
  --image-width: 100%;
  --image-position: auto;

  position: relative;
  width: 100%;
  height: 500px;

  a {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
  }

  &_image {
    flex: 1 0 0;
    background-color: var(--background-color);
    overflow: hidden;

    img {
      display: block;
      width: var(--image-width);
      height: 100%;
      object-fit: cover;
      object-position: var(--image-position);
    }
  }

  &_text {
    padding: var(--padding_cards);
    background-color: var(--background-color);

    @include mobile {
      padding: var(--spacing-small);
    }
  }

  &_description {
    max-width: 32em;
  }
}

.other-services-cta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color_background);
  padding: var(--padding_cards);

  &_text {
    display: flex;
    align-items: center;

    span {
      flex: 1 1 auto;
    }
  }
}
