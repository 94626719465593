@import "src/styles/2-design-tokens/media-queries";

.quality-section {
  .grid {
    justify-content: center;
    grid-gap: var(--spacing_36px);

    @include tabletAndDesktop {
      grid-gap: var(--gap);
    }
  }

  figure {
    grid-column: 1 / -1;
    grid-row: 2;
    display: flex;
    align-items: center;
    flex-direction: column;

    a {
      display: grid;
      place-items: center;
    }

    /*
    * Only used for testimonials, not for logos
    img {
      width: 100%;
      max-width: 200px;
    } */

    figcaption {
      margin-top: 24px;
      font-size: var(--font-size_small);
      text-align: center;
    }

    @include tablet {
      grid-column: 2 / span 2;
      grid-row: 1;
    }

    @include desktop {
      grid-column: 2 / span 2;
      grid-row: 1;
    }
  }

  p {
    grid-column: 1 / -1;
    grid-row: 1;
    text-align: center;

    @include tabletAndDesktop {
      text-align: left;
      grid-column: 5 / span 7;
    }
  }
}
