@import "src/styles/2-design-tokens/media-queries";
@import "src/styles/6-layout/content-layout";

footer {
  background-color: var(--color_background);
  line-height: inherit;
  padding: 70px 0;
  margin-top: var(--spacing_footer);
}

.footer-wrapper {
  @include contentLayout;
  display: grid;
  grid-template-areas: "address"
                                         "contact-links"
                                         "copyright"
                                         "legal-links";
  grid-gap: var(--spacing_24px);

  .address {
    font-size: var(--font-size_large);
    margin: 0;
  }

  @include tabletAndDesktop {
    grid-template-areas: "address contact-links"
                                             "copyright legal-links";
    grid-template-columns: 1fr min-content;
    grid-gap: var(--spacing_36px);
  }
}

.contact-links {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: min-content;

  a ui-button {
    width: 100%;
  }

  a + a {
    margin-top: var(--spacing_12px);
  }

  @include tabletAndDesktop {
    align-items: stretch;
    justify-self: flex-end;
  }
}

.legal-links {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: flex-start;

  > * + * {
    margin-top: var(--spacing_12px);
  }

  @include tabletAndDesktop {
    flex-direction: row;
    justify-content: flex-end;

    > * + * {
      margin-top: 0;
      margin-left: var(--spacing_12px);
    }
  }
}