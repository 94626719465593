@import "src/styles/3-tools/fill-parent";

button {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 1em 1.8em;
  background: transparent;
  border: 3px solid;
  font: inherit;
  font-size: 1rem;

  &.button--selection {
    width: 100%;
    justify-content: center;
    border-color: var(--color_background);
    font-weight: bold;
    text-transform: none;

    &:hover {
      border-color: currentColor;
    }

    &:focus {
      border-color: var(--color_primary);
    }
  }

  &:focus {
    outline: none;
  }
}

ui-button {
  &[large] {
    --padding: var(--padding_button);
  }

  ui-arrow {
    width: 2em;
  }
}