@import "src/styles/2-design-tokens/media-queries";
@import "src/styles/3-tools/fill-parent";

.services-section {
  ui-button {
    margin-top: var(--padding_content);

    @include desktop {
      margin-top: var(--spacing-medium);
    }
  }

  ui-slider > img {
    height: 400px;
    object-fit: cover;
  }
}