@import "src/styles/6-layout/content-layout";
@import "src/styles/3-tools/fill-parent";

.claim {
  position: relative;
  z-index: 1;

  &-headline {
    font-family: var(--font-family_root);
    font-size: var(--font-size_large);
    font-weight: 400;
  }

  &-text {
    margin: var(--spacing-small) 0 var(--spacing-medium);
  }

  .word-highlight {
    color: var(--color_primary);
  }
}

.claim-section {
  > .grid {
    align-items: center;
    position: relative;
  }
}