@use "sass:math";
@import "/src/styles/3-tools/aspect";

.video-section {
  .player {
    @include aspect(math.div(16, 9));

    lite-youtube {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-width: 100%;
    }
  }
}
