@mixin fill-parent($isBehind: false) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @if $isBehind {
    z-index: -1;
  }
}