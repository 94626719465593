@mixin aspect($ratio: 1) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 0;

    @if ($ratio == 1) {
      padding-top: 100%;
    }
  }

  @supports (aspect-ratio: 1) {
    aspect-ratio: $ratio;

    &::before {
      content: none;
    }
  }
}
