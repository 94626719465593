section {
  width: 100%;
  max-width: var(--max-width);
  padding-left: var(--padding_content);
  padding-right: var(--padding_content);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 auto;
}
section + section {
  margin-top: var(--spacing_sections);
}

form {
  width: 100%;
}

label {
  display: block;
}
label > span {
  font-size: var(--font-size_large);
  font-weight: bold;
}
label > textarea,
label > input {
  margin-top: var(--spacing_12px);
}
label + label {
  margin-top: var(--spacing_36px);
}

input[type=text],
input[type=email],
textarea,
select {
  display: block;
  width: 100%;
  background-color: var(--color_background);
  border: none;
  font: var(--font-family_root);
  font-size: var(--font-size_body);
  font-weight: normal;
  padding: 30px 36px;
  margin-left: 0;
  margin-right: 0;
}
input[type=text]:active,
input[type=email]:active,
textarea:active,
select:active {
  outline: 1px var(--color_primary);
}

input[type=checkbox] {
  width: 24px;
  height: 24px;
  border: 3px solid var(--color_text);
  background: transparent;
  margin: 0;
}

textarea {
  resize: none;
}

.radio {
  display: flex;
  align-items: center;
}
.radio input {
  margin-top: 0;
  margin-right: var(--spacing_12px);
}

button {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding: 1em 1.8em;
  background: transparent;
  border: 3px solid;
  font: inherit;
  font-size: 1rem;
}
button.button--selection {
  width: 100%;
  justify-content: center;
  border-color: var(--color_background);
  font-weight: bold;
  text-transform: none;
}
button.button--selection:hover {
  border-color: currentColor;
}
button.button--selection:focus {
  border-color: var(--color_primary);
}
button:focus {
  outline: none;
}

ui-button[large] {
  --padding: var(--padding_button);
}
ui-button ui-arrow {
  width: 2em;
}

ol {
  margin: var(--spacing_36px) 0;
  padding: 0;
  font-size: var(--font-size_large);
  counter-reset: section;
  list-style-type: none;
}
ol li {
  display: flex;
  font-size: var(--font-size_large);
  font-weight: bold;
}
ol li::before {
  counter-increment: section;
  content: "0" counters(section, "") ".";
  margin-right: var(--spacing_12px);
  font-weight: normal;
  color: var(--color_weak-text);
}
@media screen and (min-width: 769px) {
  ol li::before {
    margin-right: var(--spacing_24px);
  }
}

li {
  padding-top: 0;
}
li small {
  display: block;
  font-size: var(--font-size_body);
  font-weight: normal;
}
li + li {
  margin-top: var(--spacing_12px);
}

dl {
  display: flex;
  flex-direction: column;
  gap: var(--spacing_24px);
  width: 100%;
  max-width: 700px;
}

a {
  text-decoration: none;
  color: inherit;
}

.text-link {
  display: inline;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background-size: 0 3px, 100% 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(0deg, var(--border-color_underline--hover), var(--border-color_underline--hover)), linear-gradient(0deg, var(--border-color_underline), var(--border-color_underline));
  transition: background-size 0.3s cubic-bezier(0.09, 0.7, 0.24, 1);
}
.text-link:hover, .text-link:focus {
  background-size: 100% 3px, 100% 3px;
}

footer {
  background-color: var(--color_background);
  line-height: inherit;
  padding: 70px 0;
  margin-top: var(--spacing_footer);
}

.footer-wrapper {
  width: 100%;
  max-width: var(--max-width);
  padding-left: var(--padding_content);
  padding-right: var(--padding_content);
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-areas: "address" "contact-links" "copyright" "legal-links";
  grid-gap: var(--spacing_24px);
}
.footer-wrapper .address {
  font-size: var(--font-size_large);
  margin: 0;
}
@media screen and (min-width: 769px) {
  .footer-wrapper {
    grid-template-areas: "address contact-links" "copyright legal-links";
    grid-template-columns: 1fr min-content;
    grid-gap: var(--spacing_36px);
  }
}

.contact-links {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: min-content;
}
.contact-links a ui-button {
  width: 100%;
}
.contact-links a + a {
  margin-top: var(--spacing_12px);
}
@media screen and (min-width: 769px) {
  .contact-links {
    align-items: stretch;
    justify-self: flex-end;
  }
}

.legal-links {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: flex-start;
}
.legal-links > * + * {
  margin-top: var(--spacing_12px);
}
@media screen and (min-width: 769px) {
  .legal-links {
    flex-direction: row;
    justify-content: flex-end;
  }
  .legal-links > * + * {
    margin-top: 0;
    margin-left: var(--spacing_12px);
  }
}

blockquote {
  margin: 0;
  font-size: var(--font-size_large);
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}

cite {
  display: block;
  color: var(--color_weak-text);
  font-style: normal;
  margin-top: var(--spacing_36px);
}
cite span {
  color: var(--color_text);
}
cite span:after {
  content: " - ";
}

.claim {
  position: relative;
  z-index: 1;
}
.claim-headline {
  font-family: var(--font-family_root);
  font-size: var(--font-size_large);
  font-weight: 400;
}
.claim-text {
  margin: var(--spacing-small) 0 var(--spacing-medium);
}
.claim .word-highlight {
  color: var(--color_primary);
}

.claim-section > .grid {
  align-items: center;
  position: relative;
}

.services-section ui-button {
  margin-top: var(--padding_content);
}
@media screen and (min-width: 1281px) {
  .services-section ui-button {
    margin-top: var(--spacing-medium);
  }
}
.services-section ui-slider > img {
  height: 400px;
  object-fit: cover;
}

.service-preview {
  --image-width: 100%;
  --image-position: auto;
  position: relative;
  width: 100%;
  height: 500px;
}
.service-preview a {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
.service-preview_image {
  flex: 1 0 0;
  background-color: var(--background-color);
  overflow: hidden;
}
.service-preview_image img {
  display: block;
  width: var(--image-width);
  height: 100%;
  object-fit: cover;
  object-position: var(--image-position);
}
.service-preview_text {
  padding: var(--padding_cards);
  background-color: var(--background-color);
}
@media screen and (max-width: 768px) {
  .service-preview_text {
    padding: var(--spacing-small);
  }
}
.service-preview_description {
  max-width: 32em;
}

.other-services-cta {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color_background);
  padding: var(--padding_cards);
}
.other-services-cta_text {
  display: flex;
  align-items: center;
}
.other-services-cta_text span {
  flex: 1 1 auto;
}

.grid .service-list {
  margin-top: var(--spacing_image-translation);
}
.grid .service-list_content, .grid .service-list_images {
  grid-row: 1;
}
.grid .service-list_content {
  grid-column: 1/span 3;
  position: relative;
  z-index: 1;
  padding: var(--padding_cards--large);
  background-color: var(--color_background);
}
.grid .service-list_content h2 {
  text-align: left;
}
@media screen and (max-width: 768px) {
  .grid .service-list_content {
    margin-left: calc(-1 * var(--padding_content));
  }
}
@media screen and (min-width: 769px) and (max-width: 1280px) {
  .grid .service-list_content {
    grid-column-end: span 5;
  }
}
@media screen and (min-width: 1281px) {
  .grid .service-list_content {
    grid-column-end: span 6;
  }
}
.grid .service-list_images {
  grid-column: 2/span 2;
  display: grid;
  grid-template-areas: "image1 image1" "image2 image3";
  grid-gap: var(--gap);
  margin-top: calc(-1 * var(--spacing_image-translation));
  margin-bottom: var(--spacing_image-translation);
  max-height: 640px;
  height: 100%;
}
.grid .service-list_images > *:first-child {
  height: 100%;
  width: 100%;
}
.grid .service-list_images > *:nth-child(1) {
  grid-area: image1;
}
.grid .service-list_images > *:nth-child(2) {
  grid-area: image2;
}
@media screen and (max-width: 768px) {
  .grid .service-list_images {
    width: calc(100% + var(--padding_content));
  }
}
@media screen and (min-width: 769px) and (max-width: 1280px) {
  .grid .service-list_images {
    grid-column: 4/span 5;
  }
}
@media screen and (min-width: 1281px) {
  .grid .service-list_images {
    grid-column: 6/span 7;
  }
}
.grid .service-list p {
  font-size: var(--font-size_large);
}

.testimonials-section {
  overflow: visible;
}

.tradition-section .grid {
  align-items: center;
}
@media screen and (min-width: 1281px) {
  .tradition-section .team_text,
.tradition-section .team_image {
    grid-column: auto/span 6;
  }
}
@media screen and (min-width: 1281px) {
  .tradition-section .team_image {
    width: 50vw;
    position: relative;
    left: 100%;
    transform: translate(-50vw, 0);
  }
}
.tradition-section .team_image img {
  display: block;
  width: 100%;
}
.tradition-section .team_text p {
  margin-bottom: var(--spacing-small);
}

.cookie-consent-banner {
  background-color: #fff;
  padding: var(--padding_cards);
  border: 4px solid var(--color_text);
  max-height: 90vh;
  overflow: auto;
}
.cookie-consent-banner_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  padding: var(--spacing_24px);
  background-color: rgba(255, 255, 255, 0.8);
}
@media screen and (min-width: 769px) {
  .cookie-consent-banner_wrapper {
    padding: var(--padding_content);
  }
}
.cookie-consent-banner_content {
  margin-bottom: var(--spacing_24px);
  max-width: 50ch;
}
.cookie-consent-banner_content > strong {
  margin-top: var(--spacing_24px);
  margin-bottom: var(--spacing_12px);
}
.cookie-consent-banner_content ul {
  padding: 0;
  list-style: none;
}
.cookie-consent-banner_content li {
  display: flex;
}
.cookie-consent-banner_content .cookie-description {
  flex: 1 0 0;
  flex-direction: column;
  margin-left: var(--spacing_12px);
}
.cookie-consent-banner_content .cookie-description p {
  font-size: var(--font-size_small);
}
.cookie-consent-banner ui-button + ui-button {
  margin-top: var(--spacing_12px);
}
@media screen and (min-width: 769px) {
  .cookie-consent-banner ui-button + ui-button {
    margin-top: 0;
    margin-left: var(--spacing_12px);
  }
}

.cookie-mandatory-info {
  margin-top: var(--spacing_36px);
  padding: var(--padding_content);
  background-color: var(--color_background);
}
.cookie-mandatory-info ui-button {
  margin-top: var(--spacing_12px);
}

.video-section .player {
  position: relative;
}
.video-section .player::before {
  content: "";
  display: block;
  width: 100%;
  height: 0;
}
@supports (aspect-ratio: 1) {
  .video-section .player {
    aspect-ratio: 1.7777777778;
  }
  .video-section .player::before {
    content: none;
  }
}
.video-section .player lite-youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.quality-section .grid {
  justify-content: center;
  grid-gap: var(--spacing_36px);
}
@media screen and (min-width: 769px) {
  .quality-section .grid {
    grid-gap: var(--gap);
  }
}
.quality-section figure {
  grid-column: 1/-1;
  grid-row: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  /*
  * Only used for testimonials, not for logos
  img {
    width: 100%;
    max-width: 200px;
  } */
}
.quality-section figure a {
  display: grid;
  place-items: center;
}
.quality-section figure figcaption {
  margin-top: 24px;
  font-size: var(--font-size_small);
  text-align: center;
}
@media screen and (min-width: 769px) and (max-width: 1280px) {
  .quality-section figure {
    grid-column: 2/span 2;
    grid-row: 1;
  }
}
@media screen and (min-width: 1281px) {
  .quality-section figure {
    grid-column: 2/span 2;
    grid-row: 1;
  }
}
.quality-section p {
  grid-column: 1/-1;
  grid-row: 1;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .quality-section p {
    text-align: left;
    grid-column: 5/span 7;
  }
}
/*# sourceMappingURL=index.31572797.css.map */
