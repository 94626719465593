@import "src/styles/2-design-tokens/media-queries";

ol {
  margin: var(--spacing_36px) 0;
  padding: 0;
  font-size: var(--font-size_large);
  counter-reset: section;
  list-style-type: none;

  li {
    display: flex;
    font-size: var(--font-size_large);
    font-weight: bold;

    &::before {
      counter-increment: section;
      content: "0" counters(section, "") ".";
      margin-right: var(--spacing_12px);
      font-weight: normal;
      color: var(--color_weak-text);

      @include tabletAndDesktop {
        margin-right: var(--spacing_24px);
      }
    }
  }
}

li {
  padding-top: 0;

  small {
    display: block;
    font-size: var(--font-size_body);
    font-weight: normal;
  }

  & + li {
    margin-top: var(--spacing_12px);
  }
}

dl {
  display: flex;
  flex-direction: column;
  gap: var(--spacing_24px);
  width: 100%;
  max-width: 700px;
}