@import "src/styles/3-tools/fill-parent";
@import "src/styles/2-design-tokens/media-queries";

.cookie-consent-banner {
  background-color: #fff;
  padding: var(--padding_cards);
  border: 4px solid var(--color_text);
  max-height: calc(90vh);
  overflow: auto;

  &_wrapper {
    @include fill-parent;

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    padding: var(--spacing_24px);
    background-color: rgba(255, 255, 255, 0.8);

    @include tabletAndDesktop {
      padding: var(--padding_content);
    }
  }

  &_content {
    margin-bottom: var(--spacing_24px);
    max-width: 50ch;

    > strong {
      margin-top: var(--spacing_24px);
      margin-bottom: var(--spacing_12px);
    }

    ul {
      padding: 0;
      list-style: none;
    }

    li {
      display: flex;
    }

    .cookie-description {
      flex: 1 0 0;
      flex-direction: column;
      margin-left: var(--spacing_12px);

      p {
        font-size: var(--font-size_small);
      }
    }
  }

  ui-button + ui-button {
    margin-top: var(--spacing_12px);

    @include tabletAndDesktop {
      margin-top: 0;
      margin-left: var(--spacing_12px);
    }
  }
}

.cookie-mandatory-info {
  margin-top: var(--spacing_36px);
  padding: var(--padding_content);
  background-color: var(--color_background);

  ui-button {
    margin-top: var(--spacing_12px);
  }
}