a {
  text-decoration: none;
  color: inherit;
}


.text-link {
  display: inline;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background-size: 0 3px, 100% 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(0deg, var(--border-color_underline--hover), var(--border-color_underline--hover)),
  linear-gradient(0deg, var(--border-color_underline), var(--border-color_underline));
  transition: background-size .3s cubic-bezier(.09, .7, .24, 1);

  &:hover,
  &:focus {
    background-size: 100% 3px, 100% 3px;
  }
}

